import React, {useState, Fragment} from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Menu, Icon } from 'semantic-ui-react'
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"


/*<Menu>
  <Menu.Item as={NavLink} exact to="/" name="home" />
  {
    props.pages && props.pages.map(item => (
    <Menu.Item as={NavLink} exact to={`/page/${item.path}`} name={item.name} key={item.id}/>
  ))
}
<Menu.Item target="_blank" href="https://patyegarang.yalbilinya.com.au/" name="Patyegarang"/>
</Menu>*/


const NavBar = (props) => {

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const toggleHamburger = () =>{ setHamburgerOpen(!hamburgerOpen) }


  const main_nav = props.pages.filter(page => page.reference!==true && page.path!=="reference")
  main_nav.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);} );
  const main_menu=main_nav.map(item => (
    <Menu.Item as={NavLink} exact to={`/page/${item.path}`} name={item.name} content={item.name} key={item.id} onClick={toggleHamburger}/>
  ));

  const reference_page = props.pages.filter(page => page.path==="reference");
  const reference_title = reference_page.length>0 ? reference_page[0].name : "Reference materials";
  const reference_nav = props.pages.filter(page => page.reference===true)
  reference_nav.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);} );
  const reference_menu =
  <Menu.Item>
    <NavLink to={`/page/reference`} className="menuLink" onClick={toggleHamburger}>{reference_title}</NavLink>
    <Menu.Menu className="submenu">
    {reference_nav.map(item => (
      <Menu.Item as={NavLink} exact to={`/page/${item.path}`} name={item.name} content={item.name} key={item.id} onClick={toggleHamburger}/>
    ))
    }
    </Menu.Menu>
  </Menu.Item>



  return (
    <Fragment>
      {hamburgerOpen &&
        <Menu vertical>
          <Icon
            title="Menu"
            onClick={toggleHamburger}
            size='big'
            className="menuClose"
            name="close link"
            />

            <div className="navigationScroll">

            <Menu.Item as={NavLink} exact to="/" name="home" className="first" onClick={toggleHamburger} />
            <Menu.Item as={NavLink} exact to="/search" name="search" onClick={toggleHamburger} />

            {main_nav && main_menu}

            {reference_nav && reference_menu}
          </div>


        </Menu>
        }

        <Icon
          title="Menu"
          onClick={toggleHamburger}
          size='big'
          className="menuOpen"
          name="link bars" />

      </Fragment>
  )
}

export default NavBar
