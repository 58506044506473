import React, {Fragment} from "react"
import Parser from 'html-react-parser'
import parse from 'html-react-parser';
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { HashLink } from 'react-router-hash-link';

import {RawHTML} from "./RawHTML"
import NavBar from "./NavBar"

import { useParams, Link} from 'react-router-dom'
import { Container, Header, List, Label, Card, Icon, Image } from 'semantic-ui-react'
import flalogo from '../images/fla_logo_small.png'

const scrollTop = () =>{
  var scrollDiv = document.getElementById('pageContainer');
  scrollDiv.scrollTo({top: 0, behavior: 'smooth'});
}


const Page = () => {
  //get vars of page path and item id (if applicable) from URL path
  let { path, item_id } = useParams()

  //get data from firebase
  let option = [{collection: "page"}, {collection:"provider"}, {collection:"item", orderBy: ['order', 'asc']}]
  useFirestoreConnect(option)
  const pages = useSelector(state => state.firestore.ordered.page)
  const providers = useSelector(state => state.firestore.ordered.provider)
  const items = useSelector(state => state.firestore.ordered.item)

  let data=null
  let page= null
  let page_id=null
  let page_title=null
  let page_content=null
  let nav_content=null
  let reference_nav=null

  if (isLoaded(pages) && isLoaded(items)) {
    //get the right page according to url path
    let page = pages.filter(page => page.path===path)
    data=page[0];
    page_title=data.name;

    nav_content = pages.filter(page => page.menu===true)
    page_id=pages.filter(s => s.path === path)[0].id
    if (isLoaded(items)) {
      //show all items for the page or only the one item (if there is an item id in the URL string it will show only the item)
      page_content = (item_id) ?  items.filter(item => item.id===item_id) : items.filter(item => item.page===page_id);
    }

    reference_nav = pages.filter(page => page.reference===true)
    reference_nav.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);} );

  }

  const getName = (category, id) => {
    return category.filter(s => s.id === id)[0].name
  }

  const getLinkHTML = (url) => {
    return (url)? <p><strong>More:</strong>&nbsp;<a href={url} target="_blank">{url}</a></p>:null
  }

  const getDocumentHTML = (doc) => {
    return (doc)? <p><strong><a href={doc.src} target="_blank">Download document</a></strong></p>:null
  }

  const getImageHTML = (image) => {
    return (image)? <img className="contentImage" src={image.src} alt={image.title}/>:null
  }

  const getVideoHTML = (video) => {
    return (video)? <p><strong>Watch the video:</strong>&nbsp;<a href={video} target="_blank">{video}</a></p>:null
  }


  if (!data || !page_content){
      return(
        <p>Loading</p>
      )
    }


  return (

    <Container className="page">

    <Container className="title">
      {nav_content && <NavBar pages={nav_content}/>}
      <div className="titleHeading">
        <div className="mainHeading">Winhangarra</div>
        <div className="subHeading">National First Languages Education Portal</div>
      </div>
      <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} size="small" className="titleLogo" src={flalogo} alt="First Languages Australia" />
    </Container>




    <div id="pageContainer">
    <div className="pageContent">

    {page_title &&!item_id &&
      <div className="contentSegment">
        <h1>{page_title}</h1>
      </div>
    }

    {data.info&&!item_id&&
      <div className="contentIntroduction">
        {parse(data.info)}
      </div>
    }

    {data.path && data.path==="reference" && reference_nav.length>0 &&
      <div className="contentSegment">
      <ul>
      {reference_nav.map(item => (
          <li key={item.id}>
            <Link  to={`${'/page/'}${item.path}`}>{item.name}</Link>
          </li>
      ))}
      </ul>
      </div>
    }

    {page_content &&!item_id &&
      <div className="contentSegment">
      <ul>
      {page_content.map(content => (
        <li key={content.id}>
        <HashLink smooth to={`${'/page/'}${data.path}${'#'}${content.id}`}>{content.name}</HashLink>
        </li>
      ))}
      </ul>
      </div>
    }

    {page_content&&page_content.map(content => (
        <div className="contentGrey itemContent" key={content.id} id={content.id}>
          <h2>



            {content.name}

            {!item_id &&
              <Link target="_blank" to={`${'/page/'}${data.path}${'/'}${content.id}`}>
              <Icon name="linkify" size="small" className="linkIcon"/>
              </Link>
            }
          </h2>
          <h3>{providers&&content.provider&&getName(providers, content.provider)}</h3>
          <Fragment>{content.image&&getImageHTML(content.image)}</Fragment>
          <Fragment>{content.text&&parse(content.text)}</Fragment>
          <Fragment>{content.video&&getVideoHTML(content.video)}</Fragment>
          <Fragment>{content.document&&getDocumentHTML(content.document)}</Fragment>

          <Fragment>{content.url&&getLinkHTML(content.url)}</Fragment>
        </div>
    ))}
    </div>
    {!item_id &&
      <Container className="backTopDiv">
        <Icon title="Back to top" onClick={scrollTop} size='big' className="backToTopNav" name='chevron circle up' />
      </Container>
    }

    </div>




    </Container>

  )
}

export default Page
