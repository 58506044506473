import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { combineReducers, createStore } from "redux"
import { firebaseReducer, ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance, firestoreReducer } from "redux-firestore"

import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

import './index.css';
import App from './App';
import ReactGA from "react-ga4";

import 'semantic-ui-css/semantic.min.css'

const firebaseConfig = {
    apiKey: "AIzaSyD3YHTZ8AexLT_WzLqJAbgpaKtDaBtxgM4",
    authDomain: "yalbilinya-552dd.firebaseapp.com",
    projectId: "yalbilinya-552dd",
    storageBucket: "yalbilinya-552dd.appspot.com",
    messagingSenderId: "814809284520",
    appId: "1:814809284520:web:55432d97e4415bce12e79d",
    measurementId: "G-JQHWJ5MRW4"
  };

  firebase.initializeApp(firebaseConfig)
  firebase.firestore()

  // Redux store setup
  const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer
  })
  const initialState = {}
  const store = createStore(
    rootReducer,
    initialState
  )

  // React-Redux-Firebase config with Firestore
  const rrfProps = {
    firebase,
    config: {},
    dispatch: store.dispatch,
    createFirestoreInstance
  }

  ReactGA.initialize("G-JQHWJ5MRW4");

  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
  )
