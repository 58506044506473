import React, {Fragment, useState, useEffect} from "react"
import Parser from 'html-react-parser'
import parse from 'html-react-parser';
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"

import {RawHTML} from "./RawHTML"
import NavBar from "./NavBar"

import { useParams, Link} from 'react-router-dom'
import { Container, Header, List, Label, Card, Icon, Image, Input } from 'semantic-ui-react'
import flalogo from '../images/fla_logo_small.png'

const scrollTop = () =>{
  var scrollDiv = document.getElementById('pageContainer');
  scrollDiv.scrollTo({top: 0, behavior: 'smooth'});
}


const Search = () => {

  //get data from firebase
  let option = [{collection: "page"}, {collection:"provider"}, {collection:"item"}]
  useFirestoreConnect(option)
  const pages = useSelector(state => state.firestore.ordered.page)
  const providers = useSelector(state => state.firestore.ordered.provider)
  const items = useSelector(state => state.firestore.ordered.item)



  let data=null
  let nav_content=null

  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const changeSearchTerm = e => { setSearchTerm(e.target.value)  }

  const addItemToFiltered = (filtered, item) => {
    return [...filtered, { ...item }]
  }

  const getName = (category, id) => {
    return category.filter(s => s.id === id)[0].name
  }

  const getPath = (category, id) => {
    return category.filter(s => s.id === id)[0].path
  }

  const getLinkHTML = (url) => {
    return (url)? <p><strong>More:</strong>&nbsp;<a href={url} target="_blank">{url}</a></p>:null
  }

  const getDocumentHTML = (doc) => {
    return (doc)? <p><strong>Download:</strong>&nbsp;<a href={doc.src} target="_blank">{doc.title}</a></p>:null
  }

  const getImageHTML = (image) => {
    return (image)? <img className="contentImage" src={image.src} alt={image.title}/>:null
  }

  const getVideoHTML = (video) => {
    return (video)? <p><strong>Watch the video:</strong>&nbsp;<a href={video} target="_blank">{video}</a></p>:null
  }

  useEffect(() => {
    if (!items || items === searchResults) return
    setSearchResults(filterData(items))
  }, [items, searchTerm])

  const filterData = data => {
    let filtered = []

    // go through data
    if (data) {
      data.map(item => {

        //check title matches search terms
        const titleMatch = item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false

        //check text matches search terms
        const textMatch = item.text && item.text.toLowerCase().includes(searchTerm.toLowerCase()) ? true : false

        // Filter items by search matches
        if (titleMatch || textMatch) {
          filtered = addItemToFiltered(filtered, item)
        }
        }
      )
    }
    return filtered
  }

  if (isLoaded(pages) && isLoaded(items)) {

    nav_content = pages.filter(page => page.menu===true)

  }

  return (

    <Container className="page">

    <Container className="title">
    {nav_content && <NavBar pages={nav_content}/>}
      <div className="titleHeading">
        <div className="mainHeading">Winhangarra</div>
        <div className="subHeading">National First Languages Education Portal</div>
      </div>
      <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} size="small" className="titleLogo" src={flalogo} alt="First Languages Australia" />
    </Container>




    <div id="pageContainer">
    <div className="pageContent">


      <div className="contentSegment">
        <h1>Search</h1>
        <Input className="searchInput"  icon='search' placeholder='Search for something ...' onChange={changeSearchTerm} />

        {searchResults.length===0 && searchTerm &&
          <p>Nothing found, please try another search.</p>
        }

        {searchResults && searchResults.map(content => (

          <div className="contentGrey itemContent" key={content.id} id={content.id}>
            <h2>
              {content.name}

              <Link target="_blank" to={`${'/page/'}${getPath(pages, content.page)}${'/'}${content.id}`}>
                <Icon name="linkify" size="small" className="linkIcon"/>
              </Link>

              <div className="pageIdentifier"> in&nbsp;
              <Link to={`${'/page/'}${getPath(pages, content.page)}`}>
                {getName(pages, content.page)}
              </Link>
              </div>
            </h2>
            <h3>{providers&&content.provider&&getName(providers, content.provider)}</h3>
            <Fragment>{content.image&&getImageHTML(content.image)}</Fragment>
            <Fragment>{content.text&&parse(content.text)}</Fragment>
            <Fragment>{content.video&&getVideoHTML(content.video)}</Fragment>
            <Fragment>{content.document&&getDocumentHTML(content.document)}</Fragment>
            <Fragment>{content.url&&getLinkHTML(content.url)}</Fragment>
          </div>

        ))}

      </div>



      <Container className="backTopDiv">
        <Icon title="Back to top" onClick={scrollTop} size='big' className="backToTopNav" name='chevron circle up' />
      </Container>

    </div>


</div>

    </Container>

  )
}

export default Search
