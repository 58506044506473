import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom'
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"

import Home from './Components/Home'
import Page from './Components/Page'
import Search from './Components/Search'

function App() {

  return (
    <BrowserRouter>
      <Route path="/" component={Home} exact/>
      <Route path="/search" component={Search} exact/>
      <Route path="/page/:path" component={Page} exact/>
      <Route path="/page/:path/:item_id" component={Page} exact/>

    </BrowserRouter>
  );
}

export default App;
