import React, {useState, Fragment} from 'react'
import Parser from 'html-react-parser'
import { Container, Image, Icon} from 'semantic-ui-react'
import { NavLink} from 'react-router-dom'

import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useSelector } from "react-redux"
import {RawHTML} from "./RawHTML"
import NavBar from "./NavBar"
import artwork from '../images/artwork-thumbnail.jpg'
import flalogo from '../images/fla_logo_small.png'

const scrollTop = () =>{
  var scrollDiv = document.getElementById('pageContainer');
  scrollDiv.scrollTo({top: 0, behavior: 'smooth'});
}

const Home = () => {

  let option = {collection: "page"}
  useFirestoreConnect(option)
  const pages = useSelector(state => state.firestore.ordered.page)

  let home_content= null
  let nav_content=null
  let tile_content=null
  let credit_content=null
  let artwork_content=null
  if (isLoaded(pages)) {
    let page_filter = pages.filter(page => page.name==="Home")
    home_content = page_filter[0]
    page_filter = pages.filter(page => page.name==="Home - Credits")
    credit_content = page_filter[0]
    page_filter = pages.filter(page => page.name==="Home - Artwork")
    artwork_content = page_filter[0]
    nav_content = pages.filter(page => page.menu===true)
    tile_content = pages.filter(page => page.tile===true)
    tile_content.sort(function(a,b) {return (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0);} );

  }

  if (!home_content){
      return(
        <p>Loading</p>
      )
    }


  return (
    <Container className="page">

    <Container className="title">
      {nav_content && <NavBar pages={nav_content}/>}
      <div className="titleHeading">
        <div className="mainHeading">Winhangarra</div>
        <div className="subHeading">National First Languages Education Portal</div>
      </div>
      <Image onClick={()=>window.open("https://www.firstlanguages.org.au/")} size="small" className="titleLogo" src={flalogo} alt="First Languages Australia" />
    </Container>






    <div id="pageContainer">
    <div className="pageContent">

    <div className="contentSegment">
      {home_content && home_content.info &&
        <RawHTML>{home_content.info}</RawHTML>
      }
    </div>

    {tile_content &&
      <div className="contentGrey">
        <div className="tileContainer">
        {tile_content.map(item => (
            <NavLink
              exact to={`/page/${item.path}`}
              className="tile"
              style={{backgroundColor: item.hex}}>
            {item.name}
            </NavLink>
        ))}
        </div>
      </div>
    }

    <div className="contentSegment contentCentre">
      <h3>
        <NavLink exact to={`/search`} className="searchLink">
        <Icon title="Search" size='small'  name='search' />
        Search Winhangarra
        </NavLink>

      </h3>
    </div>

      <div className="homeFooter">
        {credit_content && credit_content.info &&<RawHTML>{credit_content.info}</RawHTML>}
        <hr/>
        {artwork_content && artwork_content.info &&
          <div className="credits">
              <Image className="creditsImage" src={artwork} alt="Kungkarrangkalpa (Seven Sisters) by Angilyiya Tjapiti Mitchel" />
              <div><RawHTML>{artwork_content.info}</RawHTML></div>
          </div>
        }
      </div>
    </div>
    <Container className="backTopDiv">
      <Icon title="Back to top" onClick={scrollTop} size='big' className="backToTopNav" name='chevron circle up' />
    </Container>
    </div>




    </Container>
  )
}

export default Home
